<template>
  <div class="refunds-reestr">
    <div class="row">
      <!-- TODO: Фильтр: Дата с | Дата по. По умолчанию с первого числа месяца по конец месяца.-->
      <div class="col-sm-12 col-md-6">
        <input
          class="date"
          type="date"
          v-model="dateFrom"
          placeholder="Дата с"
        />
        <input
          class="date"
          type="date"
          v-model="dateTo"
          placeholder="Дата по"
        />
      </div>
    </div>

    <v-server-table
      :url="ApiUrl"
      ref="clientsTable"
      :columns="tableColumns"
      :options="tableOptions"
      @row-click="handleRowClick"
    >
      <template v-slot:period="{ row }">
        <div>{{ row.moneyTransfers.periodFromFormatted }}</div>
        <div>{{ row.recipient }}</div>
        <div>{{ row.sum }}</div>
      </template>
    </v-server-table>
  </div>
</template>

<script>
import Api from "@/api/v1/money-transfers-org";

export default {
  name: "money-transfers-reestr",
  components: {},
  props: {
    clientId: Number,
    paymentType: String,
  },
  data() {
    return {
      dateFrom: "",
      dateTo: "",

      tableOptions: {
        perPage: 150,
        columnsClasses: {
          status: "col-status text-end",
        },
        headings: {
          period: this.$t("clientsStats.table.period"),
          actions: "",
        },
      },
    };
  },
  computed: {
    tableColumns() {
      return ["period", "actions"];
    },
    ApiUrl() {
      return (
        Api.baseUrl +
        "/clients?expand=client,moneyTransfers&from=" +
        this.dateFrom +
        "&to=" +
        this.dateTo +
        "&client_id=" +
        this.clientId +
        "&payment_type=" +
        this.paymentType
      );
    },
  },
  watch: {
    async dateFrom() {
      await this.updateTable();
    },
    async dateTo() {
      await this.updateTable();
    },
  },
  methods: {
    // handleSelect(id) {
    //   this.$emit("select", id);
    // },
    async confirm(id) {
      await Api.deleteModel(id);
      this.$refs.clientsTable.getData();
      this.confirmShow = false;
    },
    async updated() {
      await this.updateTable();
    },
    async updateTable() {
      await this.$refs.clientsTable.refresh();
    },
    setDefaultDate() {
      let currentDate = new Date();
      let currentMonth = (currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      let currentYear = currentDate.getFullYear();
      let currentDay = currentDate.getDate().toString().padStart(2, "0");

      this.dateFrom = currentYear + "-" + currentMonth + "-" + "01";
      this.dateTo = currentYear + "-" + currentMonth + "-" + currentDay;
    },
    handleRowClick({ row }) {
      this.$emit("select", row);
    },
  },
  async created() {
    this.setDefaultDate();
    this.updateTable();
  },
};
</script>
