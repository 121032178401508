<template>
  <form class="ClientsForm">
    <div class="form-group">
      <label for="inputTitle">{{ $t("clients.form.title") }}</label>
      <input
        type="text"
        class="form-control"
        id="inputTitle"
        :state="form.isValid('title')"
        :placeholder="$t('clients.form.placeholder.title')"
        v-model="form.title"
      />
      <div class="invalid-feedback">
        {{ form.errors.get("title") }}
      </div>
    </div>

    <div class="form-group">
      <label for="inputAlias">{{ $t("clients.form.alias") }}</label>
      <input
        type="text"
        class="form-control"
        id="inputAlias"
        :state="form.isValid('alias')"
        :placeholder="$t('clients.form.placeholder.alias')"
        v-model="form.alias"
      />
      <div class="invalid-feedback">
        {{ form.errors.get("alias") }}
      </div>
    </div>

    <div class="form-group">
      <label for="inputTimezone">{{ $t("clients.form.timezone") }}</label>
      <input
        type="text"
        class="form-control"
        id="inputTimezone"
        :state="form.isValid('timezone')"
        :placeholder="$t('clients.form.placeholder.timezone')"
        v-model="form.timezone"
      />
      <div class="invalid-feedback">
        {{ form.errors.get("timezone") }}
      </div>
    </div>

    <div class="form-group">
      <label for="inputPhone">{{ $t("clients.form.phone") }}</label>
      <input
        type="tel"
        class="form-control"
        id="inputPhone"
        :state="form.isValid('phone')"
        :placeholder="$t('clients.form.placeholder.phone')"
        v-model="form.phone"
      />
      <div class="invalid-feedback">
        {{ form.errors.get("phone") }}
      </div>
    </div>

    <div class="form-group">
      <label for="inputEmail">{{ $t("clients.form.email") }}</label>
      <input
        type="email"
        class="form-control"
        id="inputEmail"
        :state="form.isValid('email')"
        placeholder="user@example.com"
        v-model="form.email"
      />
      <div class="invalid-feedback">
        {{ form.errors.get("email") }}
      </div>
    </div>
    <div class="form-group">
      <label for="inputTariff">{{ $t("clients.form.tariff") }}</label>
      <input
        type="number"
        class="form-control"
        id="inputTariff"
        :state="form.isValid('tariff')"
        value-type="format"
        :placeholder="$t('clients.form.placeholder.tariff')"
        v-model="form.tariff"
      />
      <div class="invalid-feedback">
        {{ form.errors.get("tariff") }}
      </div>
    </div>

    <div class="form-group">
      <label for="inputTariff_price">{{
        $t("clients.form.tariff_price")
      }}</label>
      <input
        type="number"
        class="form-control"
        id="inputTariff_price"
        :state="form.isValid('tariff_price')"
        :placeholder="$t('clients.form.placeholder.tariff_price')"
        v-model="form.tariff_price"
      />
      <div class="invalid-feedback">
        {{ form.errors.get("tariff_price") }}
      </div>
    </div>
    <div class="form-group">
      <label for="inputTariff_price">{{ $t("clients.form.time_start") }}</label>

      <VueDatePicker
        v-model="timestart"
        value-type="format"
        locale="ru"
        :enable-time-picker="false"
      ></VueDatePicker>

      <div class="invalid-feedback">
        {{ form.errors.get("time_start") }}
      </div>
    </div>

    <div class="form-group">
      <label for="inputTariff_status">{{
        $t("clients.form.status.title")
      }}</label>

      <select v-model="form.status" class="form-select" id="inputTariff_status">
        <option :value="STATUS_ACTIVE">
          {{ $t("clients.form.status.active") }}
        </option>
        <option :value="STATUS_NOT_ACTIVE">
          {{ $t("clients.form.status.not_active") }}
        </option>
      </select>
      <div class="invalid-feedback">
        {{ form.errors.get("status") }}
      </div>
    </div>
    <div class="modal-footer">
      <button
        @click="$emit('closeModal')"
        type="button"
        class="btn btn-secondary"
      >
        {{ $t("main.close") }}
      </button>
      <button @click="save" type="button" class="btn btn-primary save">
        {{ $t("main.save") }}
      </button>
    </div>
  </form>
</template>
<script>
import { STATUS_NOT_ACTIVE, STATUS_ACTIVE } from "@/utils/clients";
import Form from "@/utils/Form";
import Api from "@/api/v1/clients";
import VueDatePicker from "@vuepic/vue-datepicker";

export default {
  name: "clients-form",
  props: {
    id: Number,
  },
  emits: ["closeModal", "updateTable", "newClient"],
  components: {
    VueDatePicker,
  },
  data() {
    return {
      STATUS_NOT_ACTIVE: STATUS_NOT_ACTIVE,
      STATUS_ACTIVE: STATUS_ACTIVE,
      timestart: "",
      form: new Form({
        id: null,
        title: "",
        alias: "",
        phone: "",
        tariff: null,
        tariff_price: null,
        time_start: "",
        status: STATUS_ACTIVE,
        email: "",
        timezone: this.$t("clients.form.placeholder.timezone"),
      }),
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.form.reset();
      this.timestart = "";
    },
    async load(id) {
      const response = await Api.getModel(id);
      if (response.data.time_start != null) {
        let date = new Date(response.data.time_start * 1000);
        this.timestart = date;
      } else {
        this.timestart = "";
      }
      this.form.load(response.data);
      this.form.id = id;
    },
    async save() {
      let timestart = this.timestart;
      if (timestart !== "" && timestart !== null) {
        timestart.setHours(0, 0, 0, 0);
        this.form.time_start = Date.parse(timestart) / 1000;
      } else {
        this.form.time_start = null;
      }

      if (this.id === null) {
        try {
          const response = await Api.createModel(this.form.data);
          this.resetForm();
          this.$emit("newClient", response.data);
          this.$emit("updateTable");
        } catch (error) {
          this.form.onFail(error.response.data);
        }
      } else {
        try {
          await Api.updateModel(this.id, this.form.data);
          this.$emit("closeModal");
          this.$emit("updateTable");
        } catch (error) {
          this.form.onFail(error.response.data);
        }
      }
    },
  },
  async created() {
    if (this.id !== null) {
      await this.load(this.id);
    }
  },
};
</script>
