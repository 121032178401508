<template>
  <form>
    <div class="form-group">
      <label for="inputEmail">{{ $t("users.form.email") }}</label>
      <input
        type="email"
        class="form-control"
        id="inputEmail"
        :state="form.isValid('email')"
        placeholder="user@example.com"
        v-model="form.email"
      />
      <div class="invalid-feedback">
        {{ form.errors.get("email") }}
      </div>
    </div>
    <div class="form-group">
      <label for="inputName">{{ $t("users.form.username") }}</label>
      <input
        type="text"
        class="form-control"
        id="inputName"
        :state="form.isValid('username')"
        placeholder="Петров П.П."
        v-model="form.username"
      />
      <div class="invalid-feedback">
        {{ form.errors.get("username") }}
      </div>
    </div>
    <div class="form-group" v-if="!id">
      <label for="inputPassword">{{ $t("users.form.password") }}</label>
      <input
        type="password"
        class="form-control"
        id="inputPassword"
        :state="form.isValid('password')"
        placeholder="••••••••"
        v-model="form.password"
      />
      <div class="invalid-feedback">
        {{ form.errors.get("password") }}
      </div>
    </div>

    <div class="modal-footer">
      <button
        @click="$emit('closeModal')"
        type="button"
        class="btn btn-secondary"
      >
        {{ $t("main.close") }}
      </button>
      <button @click="save" type="button" class="btn btn-primary save">
        {{ $t("main.save") }}
      </button>
    </div>
  </form>
</template>
<script>
import Form from "@/utils/Form";
import Api from "@/api/v1/users";

export default {
  name: "users-form",
  props: {
    id: Number,
  },
  components: {
  },
  emits: ["closeModal", "updateTable"],
  data() {
    return {
      form: new Form({
        id: null,
        email: "",
        password: "",
        username: "",
      }),
      confirmShow: true,
    };
  },
  watch: {
    // async id(value) {
    //   if (value !== null) {
    //     await this.load(value);
    //   } else {
    //     this.form.reset();
    //   }
    // },
  },
  methods: {
    async load(id) {
      const response = await Api.getModel(id);
      this.form.load(response.data);
      this.form.id = id;
    },
    async save() {
      if (this.id === null) {
        try {
          await Api.createModel(this.form.data);
          this.form.reset();
          this.$emit("closeModal");
          this.$emit("updateTable");
        } catch (error) {
          this.form.onFail(error.response.data);
        }
      } else {
        try {
          await Api.updateModel(this.id, this.form.data);
          this.$emit("closeModal");
          this.$emit("updateTable");
        } catch (error) {
          this.form.onFail(error.response.data);
        }
      }
    },
  },
  async created() {
    if (this.id !== null) {
      await this.load(this.id);
    }
  },
};
</script>
