<template>
  <div class="transfers">
    <!-- TODO: Фильтр: Дата с | Дата по. По умолчанию с первого числа месяца по конец месяца.-->
    <input class="date" type="date" v-model="dateFrom" placeholder="Дата с" />
    <input class="date" type="date" v-model="dateTo" placeholder="Дата по" />
    <div class="stats" v-if="statsData">
      <span>
        <b>{{ $t("moneyTransfers.results.revenue") }}</b
        >: {{ statsData.revenue }}</span
      >
      <span>
        <b>{{ $t("moneyTransfers.results.fee") }}</b
        >: {{ statsData.fee }}</span
      >
      <span>
        <b>{{ $t("moneyTransfers.results.feeAverage") }}</b
        >: {{ statsData.feeAverage }}</span
      >
    </div>
    <v-server-table
      :url="ApiUrl"
      ref="transfersTable"
      :columns="tableColumns"
      :options="tableOptions"
      @row-click="handleRowClick"
    >
      <template v-slot:detail="{ row }">
        <div>
          <div>
            <span class="fw-bolder me-2"
              >{{ $t("moneyTransfers.table.dateOperation") }}:</span
            >
            <span>{{ row.operationDateFormatted }}</span>
          </div>
        </div>
        <div>
          <span class="fw-bolder me-2"
            >{{ $t("moneyTransfers.table.period") }}:</span
          >
          <span v-if="row.reporting_days === 1" class="">{{
            row.periodFromFormatted
          }}</span>
          <span v-else
            >{{ row.periodFromFormatted }} - {{ row.periodToFormatted }}</span
          >
        </div>
        <div>
          <span
            >{{ row.detail.clientsCount }}
            {{ $t("moneyTransfers.table.clientsCount") }}</span
          >
          <span> | </span>
          <span>{{ row.detail.sum }}</span>
          <span> | </span>
          <span>{{ row.detail.fee }}</span>
          <span> | </span>
          <span>{{ row.detail.feeAp }}</span>
        </div>
      </template>
      <template v-slot:status="{ row }">
        <div>{{ row.statusTitle }}</div>
      </template>
    </v-server-table>

    <transition name="fade">
      <modal-comp
        @close="modalForm.show = false"
        v-if="modalForm.show"
        :title="modalForm.title"
        size="lg"
      >
        <template v-slot:content>
          <transfers-form :id="modalForm.id" @updated="updated" />
        </template>
      </modal-comp>
    </transition>

    <transition name="fade">
      <confirm-comp
        @confirm="confirm"
        :id="confirmId"
        :title="$t('main.confirm.title')"
        :text="$t('main.confirm.delete')"
        @cancel="cancel"
        v-if="confirmShow"
      />
    </transition>
  </div>
</template>

<script>
import Api from "@/api/v1/money-transfers";
import TransfersForm from "@/components/transfers/TransfersForm";
import ConfirmComp from "@/components/ConfirmComp";
import ModalComp from "@/components/ModalComp";

export default {
  name: "money-transfers-view",
  components: {
    ModalComp,
    ConfirmComp,
    TransfersForm,
  },
  data() {
    return {
      dateFrom: "",
      dateTo: "",
      statsData: null,
      tableOptions: {
        perPage: 150,
        columnsClasses: {
          id: "col-id",
          status: "col-status text-end",
        },
        headings: {
          id: 'id',
          detail: this.$t("moneyTransfers.table.detail"),
          status: this.$t("moneyTransfers.table.status"),
          actions: "",
        },
      },

      modalForm: {
        show: false,
        id: null,
        title: "",
      },

      confirmShow: false,
      confirmId: null,
    };
  },
  computed: {
    tableColumns() {
      return ['id', "detail", "status"];
    },
    ApiUrl() {
      return (
        Api.baseUrl +
        "?expand=detail&from=" +
        this.dateFrom +
        "&to=" +
        this.dateTo
      );
    },
  },
  watch: {
    dateFrom() {
      this.getStats();
    },
    dateTo() {
      this.getStats();
    },
  },
  methods: {
    async getStats() {
      const params = {
        from: this.dateFrom,
        to: this.dateTo,
      };
      const response = await Api.getStats(params);
      this.statsData = response.data;
      await this.updateTable();
    },
    cancel() {
      this.confirmShow = false;
    },
    async confirm(id) {
      await Api.deleteModel(id);
      this.$refs.transfersTable.getData();
      this.confirmShow = false;
    },
    async updated() {
      await this.updateTable();
    },
    async updateTable() {
      await this.$refs.transfersTable.refresh();
    },
    setDefaultDate() {
      let currentDate = new Date();
      let currentMonth = (currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      let currentYear = currentDate.getFullYear();
      let currentDay = currentDate.getDate().toString().padStart(2, "0");

      this.dateFrom = currentYear + "-" + currentMonth + "-" + "01";
      this.dateTo = currentYear + "-" + currentMonth + "-" + currentDay;
    },
    handleRowClick({ row }) {
      this.modalForm.id = row.id;
      this.modalForm.show = true;
      this.modalForm.title =
        this.$t("moneyTransfers.form.modalTitle") +
        (row.operationDateFormatted ? " " + row.operationDateFormatted : "");
    },
  },
  created() {
    const response = Api.getModels();
    this.setDefaultDate();
    this.getStats();
    this.users = response.data;
  },
};
</script>
