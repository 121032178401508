<template>
  <component :is="layout"></component>
  <notifications position="bottom left" />
</template>


<script>
import "@/scss/main.scss";
import SimpleLayout from "./layouts/SimpleLayout.vue";
import EmptyLayout from "./layouts/EmptyLayout.vue";
import { USER_REQUEST } from "@/store/modules/user/actions";

export default {
  components: {
    SimpleLayout,
    EmptyLayout,
  },
  computed: {
    layout() {
      return this.$store.getters.layout + "-layout";
    },
  },
  created: function () {
    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }
  },
};
</script>