import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import { ServerTable, ClientTable } from 'v-tables-3';
import tableDefaultOptions from './table'
import Notifications from '@kyvg/vue3-notification'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap"


const app = createApp(App);
app.use(router);
app.use(store);
app.use(Notifications);
app.use(i18n);
app.use(VueDatePicker);
app.use(ServerTable, tableDefaultOptions, 'bootstrap4')
app.use(ClientTable, tableDefaultOptions, 'bootstrap4')
app.mount('#app')
