import { createStore } from 'vuex'

import user from './modules/user'
import auth from './modules/auth'
import layout from './modules/layout'
import moderator from './moderator'


const store = createStore({
    modules: {
        user,
        auth,
        layout,
    },
    plugins: [moderator],
})

export default store;