<template>
  <div id="wrapper">
    <router-view
      @collapsed="handleCollapsed"
      :collapsed="sidebarCollapsed"
      name="sidebar"
    ></router-view>

    <div class="section">
      <router-view name="header"></router-view>
      <div class="content">
          <router-view></router-view>
      </div>
      <router-view name="footer"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transitionName: "slide-right",
      collapsed: false,

      sidebarCollapsed: false,
    };
  },

  methods: {
    collapsedInit() {
      const width = window.innerWidth;
      if (width < 992) {
        this.sidebarCollapsed = true;
      }
    },
    handleCollapsed(value) {
      this.sidebarCollapsed = value;
    },

    isWorker() {
      return true;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },

    onCollapse(collapsed) {
      this.collapsed = collapsed;
    },
  },
};
</script>

<style lang="scss">
#wrapper {
  background-color: #e9ecef;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: row nowrap;
}

.section {
  width: 100%;
  .content {
    background: white;
    margin: 1rem;
    padding: 2rem 2rem;
    border-radius: 0.8rem;
  }
}
</style>
