<template>
  <div class="refunds">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <select v-model="clientId" class="form-select" id="inputClients">
            <option
              v-for="client in clients"
              :key="client.id"
              :value="client.id"
            >
              {{ client.alias }} / {{ client.title }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <v-server-table
      :url="ApiUrl"
      ref="refundsTable"
      :columns="tableColumns"
      :options="options"
      @row-click="handleRowClick"
    >
      <template v-slot:operation="{ row }">
        <div>
          <b> {{ row.client.alias }} </b>
        </div>
        <div>&nbsp;</div>
        <div>
          <b>{{ $t("moneyTransfersRefunds.table.operationTime") }}:</b>
          {{ row.externalPaymentTimeFormatted }}
        </div>
        <div>
          <b>{{ $t("moneyTransfersRefunds.table.operationClient") }}:</b>
          {{ row.external_payment_client }}
        </div>
        <div>
          <b>{{ $t("moneyTransfersRefunds.table.operationPayerFIO") }}:</b>
          {{ row.payer_name }}
        </div>
        <div>
          <b>{{ $t("moneyTransfersRefunds.table.operationPayerEmail") }}:</b>
          {{ row.payer_email }}
        </div>

        <div>
          <b>{{ $t("moneyTransfersRefunds.table.operationSum") }}: </b>
          <span>{{ row.external_payment_sum }}</span>
          <span class="ms-1" v-if="row.payment_option === BK">({{ $t("moneyTransfersRefunds.table.operationOptionBk") }})</span>
          <span class="ms-1" v-if="row.payment_option === SBP">({{ $t("moneyTransfersRefunds.table.operationOptionSbp") }})</span>
        </div>
        <div>
          <b>{{ $t("moneyTransfersRefunds.table.operationMethod") }}:</b>
          {{ row.external_payment_method }}
        </div>
        <div>
          <small>{{ row.external_payment_title }}</small>
        </div>
      </template>

      <template v-slot:refund="{ row }">
        <div>
          {{ row.typeTitle }}
        </div>
        <div>
          <b>{{ $t("moneyTransfersRefunds.table.refundRequestDate") }}:</b>
          {{ row.requestTimeFormatted }}
        </div>
        <div>
          <b>{{ $t("moneyTransfersRefunds.table.refundSum") }}:</b>
          {{ row.sum }}
        </div>
        <div>
          <b>{{ $t("moneyTransfersRefunds.table.refundAccountSum") }}:</b>
          {{ row.sum_account }}
        </div>
        <div :class="{ 'text-danger': row.refundTimeFormatted == null }">
          <b>{{ $t("moneyTransfersRefunds.table.refundDate") }}:</b>
          {{ row.refundTimeFormatted }}
        </div>
        <div :class="{ 'text-danger': row.reestr.period == '' }">
          <b>{{ $t("moneyTransfersRefunds.table.refundReestr") }}: </b>
          <span class="reestr" @click="handleReestrClick(row.reestr.id)">
            <i>{{ row.reestr.period }}</i>
          </span>
          &nbsp;
          <span>
            {{ $t("moneyTransfersRefunds.table.refundReestrBySum") }}
            <i>{{ row.reestr.sum }}</i>
          </span>
        </div>
        <div>
          <small>{{ row.reestr.recipient }}</small>
        </div>
      </template>

      <template v-slot:actions="{ row }">
        <table-action-buttons
          :update-visible="row.isCanEdit"
          :delete-visible="false"
          @delete="handleDelete(row.id)"
          @update="showForm(row.id)"
        >
        </table-action-buttons>
      </template>
    </v-server-table>

    <transition name="fade">
      <modal-comp
        v-if="refundsModalForm.show"
        @close="refundsModalForm.show = false"
      >
        <template v-slot:content>
          <refunds-form
            @closeModal="refundsModalForm.show = false"
            @updateTable="updateTable"
            @newClient="newClient"
            :id="refundsModalForm.id"
          />
        </template>
      </modal-comp>
    </transition>

    <transition name="fade">
      <modal-comp
        @close="modalForm.show = false"
        v-if="modalForm.show"
        :title="modalForm.title"
        size="lg"
      >
        <template v-slot:content>
          <transfers-form :id="modalForm.id" />
        </template>
      </modal-comp>
    </transition>
  </div>

  <transition name="fade">
    <confirm-comp
      @confirm="confirm"
      :id="confirmId"
      :title="$t('main.confirm.title')"
      :text="$t('main.confirm.delete')"
      @cancel="cancel"
      v-if="confirmShow"
    />
  </transition>
</template>

<script>
import Api from "@/api/v1/money-transfers-refunds";
import ApiMto from "@/api/v1/money-transfers-org";
import ModalComp from "@/components/ModalComp";
import RefundsForm from "@/components/refunds/RefundsForm";
import TableActionButtons from "@/components/TableActionButtons";
import ConfirmComp from "@/components/ConfirmComp";
import TransfersForm from "@/components/transfers/TransfersForm";
import {BK, SBP} from "@/utils/paymentOptions";

export default {
  data() {
    return {
      BK,
      SBP,
      modalForm: {
        show: false,
        id: null,
        title: "",
      },
      options: {
        perPage: 200,
        headings: {
          operation: this.$t("moneyTransfersRefunds.table.operation"),
          refund: this.$t("moneyTransfersRefunds.table.refund"),
          statusTitle: this.$t("moneyTransfersRefunds.table.status"),
          actions: "",
        },
      },
      targetForm: "refundsForm",

      refundsModalForm: {
        show: false,
        id: null,
      },

      confirmShow: false,
      confirmId: null,

      clients: [],
      clientId: null,
    };
  },
  watch: {},
  computed: {
    ApiUrl() {
      let client = "";
      if (this.clientId !== null) {
        client = "&client_id=" + this.clientId;
      }
      return Api.baseUrl + "?expand=reestr,client" + client;
    },
    tableColumns() {
      const actions = ["actions"];
      return ["operation", "refund", "statusTitle", ...actions];
    },
  },
  components: {
    ModalComp,
    RefundsForm,
    TableActionButtons,
    ConfirmComp,
    TransfersForm,
  },
  methods: {
    async getFilters() {
      const response = await ApiMto.getFilters();
      this.clients = response.data.clients;
    },
    handleReestrClick(id) {
      this.modalForm.id = id;
      this.modalForm.show = true;
      this.modalForm.title = "";
    },

    handleRowClick({ row }) {
      this.refundsModalForm.id = row.id;
      // this.modalForm.show = true;
      // this.modalForm.title =
      //   this.$t("moneyTransfers.form.modalTitle") +
      //   (row.operationDateFormatted ? " " + row.operationDateFormatted : "");
    },
    cancel() {
      this.confirmShow = false;
    },
    async confirm() {
      // await Api.deleteModel(id);
      // this.$refs.refundsTable.getData();
      this.confirmShow = false;
    },
    updateTable() {
      this.$refs.refundsTable.refresh();
    },
    async handleDelete(id) {
      this.confirmId = id;
      this.confirmShow = true;
    },
    showForm(id) {
      this.refundsModalForm.id = id;
      this.refundsModalForm.show = true;
    },
    newClient(data) {
      this.refundsModalForm.show = false;
      this.showModal(data);
    },
  },
  created() {
    this.getFilters();
    let response = Api.getModels();
    this.users = response.data;
  },
};
</script>
