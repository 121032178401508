export default {
  STATUS_WAITING: 'В обработке',
  STATUS_COMPLETED: 'Выполнен',
  export: 'Экспорт в Excel',

  results: {
    revenue: 'Выручка',
    revenue_bk: 'Выручка БК',
    revenue_sbp: 'Выручка СБП',
    revenue_with_refunds: 'Выручка с учетом возвратов',
    revenue_with_refunds_bk: 'БК',
    revenue_with_refunds_sbp: 'СБП',
    transfer: 'К зачислению',
    transfer_bk: 'К зачислению БК',
    transfer_sbp: 'К зачислению СБП',
    withdrawal: 'Выручка клиента',
    withdrawal_bk: 'Выручка клиента БК',
    withdrawal_sbp: 'Выручка клиента СБП',
    fee: 'Комиссия АП',
    fee_bk: 'Комиссия АП БК',
    fee_sbp: 'Комиссия АП СБП',
    fee_ps: 'Комиссия ПС',
    fee_ps_bk: 'Комиссия ПС БК',
    fee_ps_sbp: 'Комиссия ПС СБП',
    feeAverage: 'Средний чек',
    fee_sum: 'Сумма комиссий',
    fee_sum_bk: 'Итого БК',
    fee_sum_sbp: 'Итого СБП',
  },
  table: {
    period: 'Дата',
    client: 'Клиент',
    sum: 'Выручка',
    sum_bk: 'Выручка БК',
    sum_sbp: 'Выручка СБП',
    fee_ap: 'Комиссиия AP',
    fee_ap_bk: 'Комиссиия AP БК',
    fee_ap_sbp: 'Комиссиия AP СБП',
    fee_ps: 'Комиссиия ПС',
    fee_ps_bk: 'Комиссиия ПС БК',
    fee_ps_sbp: 'Комиссиия ПС СБП',
    transfer: 'К зачислению',
    transfer_bk: 'К зачислению БК',
    transfer_sbp: 'К зачислению СБП',
    withdrawal: 'Выручка клиента',
    withdrawal_bk: 'Выручка клиента БК',
    withdrawal_sbp: 'Выручка клиента СБП',
    bk: 'БК',
    sbp: 'СБП',
  },
  filter: {
    client: 'Клиент',
  }


  // form: {
  //   modalTitle: 'Реестр',
  //   confirmCompleteAll: 'Вы действительно хотите выполнить все?',
  //   status: 'Статус',
  //   dateOperation: 'Дата выплаты',
  //   period: 'Период отчета',
  //   clientsCount: 'Клиентов',
  //   revenue: 'Оборот',
  //   feeAp: 'AkratoPRIME',

  //   btnCreatePayment: 'Сформировать ПП',
  //   export: 'Экспорт в Excel',
  //   noAcceptance: 'Нет акцепта оферты',
  //   completeAll: 'Выполнить все',
  //   bk: 'Карты',
  //   sbp: 'СБП',
  // },

  // paymentsTable: {
  //   client: 'Клиент',
  //   sum: 'Выручка',
  //   fee: 'Комиссия',
  //   feePs: 'Банк',
  //   feeAp: 'AP',
  //   status: 'Статус',
  //   withdrawal: 'Выручка клиента',
  //   transfer: 'К зачислению на счет',

  //   btnStatusCompleted: 'Отправлено',
  //   confirmStatus: 'Действительно перевести в статус Выполнен операцию по клиенту'
  // },
}
