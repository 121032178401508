import ApiModel from '../ApiModel'

class MooneyTransfersOrgModel extends ApiModel {
  setStatus(id, status) {
    return this.api.post(`${this.baseUrl}/set-status`, {
      id: id,
      status: status,
    });
  }

  setCompleteAll(params) {
    return this.api.post(`${this.baseUrl}/set-complete-all`, params);
  }

  download(params) {
    return this.api.download(`${this.baseUrl}/export`, params);
  }

  downloadClientsStats(params) {
    return this.api.download(`${this.baseUrl}/export-clients-stats`, params);
  }

  getFilters(params) {
    return this.api.get(`${this.baseUrl}/filters`, params);
  }
  getClients(params) {
    return this.api.get(`${this.baseUrl}/clients`, params);
  }
  getClientsStats(params) {
    return this.api.get(`${this.baseUrl}/clients-stats`, params);
  }

}

export default new MooneyTransfersOrgModel('/money-transfers-org');
