<template>
  <form class="refunds-form">
    <div class="form-group">
      <label for="inputTariff_status">
        {{ $t("moneyTransfersRefunds.form.status") }}
      </label>

      <select v-model="form.status" class="form-select" id="inputTariff_status">
        <option
          v-for="(status, index) in statuses"
          :key="index"
          :value="status.id"
        >
          {{ status.title }}
        </option>
      </select>
      <div class="invalid-feedback">
        {{ form.errors.get("status") }}
      </div>
    </div>

    <div class="form-group">
      <span @click="handleFindReestr" class="select-reestr">
        {{ $t("moneyTransfersRefunds.form.pleaseSelectReestr") }}:
      </span>
      {{ reestr == "" ? "..." : reestr }}
    </div>

    <div class="form-group">
      <div class="col-sm-12 col-md-6">
        <label for="inputTariff_status">
          {{ $t("moneyTransfersRefunds.form.dateRefund") }}
        </label>

        <input
          class="form-control"
          type="date"
          v-model="form.refundTimeFormattedUS"
          placeholder=""
        />
      </div>
    </div>

    <transition name="fade">
      <modal-comp v-if="modalReestr.show" @close="modalReestr.show = false">
        <template v-slot:content>
          <transfers-org
            @closeModal="modalReestr.show = false"
            @select="handleSelect"
            :clientId="modalReestr.clientId"
            :paymentType="modalReestr.paymentType"
          />
        </template>
      </modal-comp>
    </transition>

    <div class="modal-footer">
      <button
        @click="$emit('closeModal')"
        type="button"
        class="btn btn-secondary"
      >
        {{ $t("main.close") }}
      </button>
      <button @click="save" type="button" class="btn btn-primary save">
        {{ $t("main.save") }}
      </button>
    </div>
  </form>
</template>
<script>
import Form from "@/utils/Form";
import Api from "@/api/v1/money-transfers-refunds";
import TransfersOrg from "@/components/refunds/TransfersOrg";
import ModalComp from "@/components/ModalComp";

export default {
  name: "clients-form",
  props: {
    id: Number,
  },
  emits: ["closeModal", "updateTable", "newClient"],
  components: { ModalComp, TransfersOrg },
  data() {
    return {
      statuses: [],

      modalReestr: {
        show: false,
        id: null,
        clientId: null,
        paymentType: null,
      },

      reestr: "",

      form: new Form({
        id: null,
        status: null,
        money_transfers_org_id: null,
        client_id: null,
        payment_type: null,
        refundTimeFormattedUS: null,
      }),
    };
  },
  watch: {},
  methods: {
    handleFindReestr() {
      this.modalReestr.clientId = this.form.client_id;
      this.modalReestr.paymentType = this.form.payment_type;
      this.modalReestr.show = true;
    },
    handleSelect(reestr) {
      this.modalReestr.show = false;
      this.form.money_transfers_org_id = reestr.id;
      this.reestr = reestr.moneyTransfers.periodFromFormatted;
    },
    resetForm() {
      this.form.reset();
      this.timestart = "";
    },
    async load(id) {
      const response = await Api.getModel(id);
      if (response.data.time_start != null) {
        let date = new Date(response.data.time_start * 1000);
        this.timestart = date;
      } else {
        this.timestart = "";
      }
      this.form.load(response.data);
      this.form.id = id;
    },
    async save() {
      let timestart = this.timestart;
      if (timestart !== "" && timestart !== null) {
        timestart.setHours(0, 0, 0, 0);
        this.form.time_start = Date.parse(timestart) / 1000;
      } else {
        this.form.time_start = null;
      }

      if (this.id === null) {
        try {
          const response = await Api.createModel(this.form.data);
          this.resetForm();
          this.$emit("newClient", response.data);
          this.$emit("updateTable");
        } catch (error) {
          this.form.onFail(error.response.data);
        }
      } else {
        try {
          await Api.updateModel(this.id, this.form.data);
          this.$emit("closeModal");
          this.$emit("updateTable");
        } catch (error) {
          this.form.onFail(error.response.data);
        }
      }
    },
    async filtersLoad() {
      const response = await Api.getFilters();
      this.statuses = response.data.statuses;
    },
  },
  async created() {
    await this.filtersLoad();
    if (this.id !== null) {
      await this.load(this.id);
    }
  },
};
</script>
