<template>
  <div class="clients-stats">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <select v-model="clientId" class="form-select" id="inputClients">
            <option
              v-for="client in clients"
              :key="client.id"
              :value="client.id"
            >
              {{ client.alias }} / {{ client.title }}
            </option>
          </select>
        </div>
      </div>
      <!-- TODO: Фильтр: Дата с | Дата по. По умолчанию с первого числа месяца по конец месяца.-->
      <div class="col-sm-12 col-md-6">
        <input
          class="date"
          type="date"
          v-model="dateFrom"
          placeholder="Дата с"
        />
        <input
          class="date"
          type="date"
          v-model="dateTo"
          placeholder="Дата по"
        />
      </div>
    </div>

    <div class="stats" v-for="(data, index) in statsData" :key="index">
      <div>
        {{ data.client }}
      </div>

      <div>
        <span>
          <b>{{ $t("clientsStats.results.revenue") }}</b
          >: {{ data.stats.revenue }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.revenue_bk") }}</b
          >: {{ data.stats.revenue_bk }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.revenue_sbp") }}</b
          >: {{ data.stats.revenue_sbp }}</span
        >
      </div>

      <div>
        <span>
          <b>{{ $t("clientsStats.results.revenue_with_refunds") }}</b
          >: {{ data.stats.revenue_with_refunds }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.revenue_with_refunds_bk") }}</b
          >: {{ data.stats.revenue_with_refunds_bk }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.revenue_with_refunds_sbp") }}</b
          >: {{ data.stats.revenue_with_refunds_sbp }}</span
        >
      </div>

      <div>
        <span>
          <b>{{ $t("clientsStats.results.transfer") }}</b
          >: {{ data.stats.transfer }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.transfer_bk") }}</b
          >: {{ data.stats.transfer_bk }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.transfer_sbp") }}</b
          >: {{ data.stats.transfer_sbp }}</span
        >
      </div>

      <div>
        <span>
          <b>{{ $t("clientsStats.results.withdrawal") }}</b
          >: {{ data.stats.withdrawal }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.withdrawal_bk") }}</b
          >: {{ data.stats.withdrawal_bk }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.withdrawal_sbp") }}</b
          >: {{ data.stats.withdrawal_sbp }}</span
        >
      </div>

      <div>
        <span>
          <b>{{ $t("clientsStats.results.fee") }}</b
          >: {{ data.stats.fee }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.fee_bk") }}</b
          >: {{ data.stats.fee_bk }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.fee_sbp") }}</b
          >: {{ data.stats.fee_sbp }}</span
        >
      </div>

      <div>
        <span>
          <b>{{ $t("clientsStats.results.fee_ps") }}</b
          >: {{ data.stats.fee_ps }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.fee_ps_bk") }}</b
          >: {{ data.stats.fee_ps_bk }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.fee_ps_sbp") }}</b
          >: {{ data.stats.fee_ps_sbp }}</span
        >
      </div>

      <div>
        <span>
          <b>{{ $t("clientsStats.results.fee_sum_bk") }}</b
          >: {{ data.stats.fee_bk_common }}</span
        >
        <span>
          <b>{{ $t("clientsStats.results.fee_sum_sbp") }}</b
          >: {{ data.stats.fee_sbp_common }}</span
        >
      </div>

      <div>
        <b>{{ $t("clientsStats.results.feeAverage") }}</b
        >: {{ data.stats.feeAverage }}
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6 mt-2">
          <div class="form-group">
            <button
              style="margin-right: 1em"
              class="btn btn-outline-success"
              @click="handleDownload(index)"
            >
              {{ $t("clientsStats.export") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <v-server-table
      v-if="isInit"
      :url="ApiUrl"
      ref="clientsTable"
      :columns="tableColumns"
      :options="tableOptions"
      @row-click="handleRowClick"
    >
      <template v-slot:period="{ row }">
        <div>{{ row.moneyTransfers.periodFromFormatted }}</div>
        <div>{{ row.recipient }}</div>
      </template>

      <template v-slot:sum="{ row }">
        <div>{{ row.sum }}</div>

        <div class="text-muted mt-2">
          {{ $t("clientsStats.table.bk") }}: {{ row.sum_bk }}
          <br />
          {{ $t("clientsStats.table.sbp") }}: {{ row.sum_sbp }}
        </div>
      </template>
      <template v-slot:transfer="{ row }">
        <div>{{ row.transfer }}</div>

        <div class="text-muted mt-2">
          {{ $t("clientsStats.table.bk") }}: {{ row.transfer_bk }}
          <br />
          {{ $t("clientsStats.table.sbp") }}: {{ row.transfer_sbp }}
        </div>
      </template>
      <template v-slot:withdrawal="{ row }">
        <div>{{ row.withdrawal }}</div>
        <div
          :class="{ 'text-danger': row.refundsSum != 0 }"
          v-if="row.refundsSum != 0"
        >
          {{ row.refundsSum }}
        </div>
        <div v-if="row.refundsSum != 0">{{ row.amount }}</div>

        <div class="text-muted mt-2">
          {{ $t("clientsStats.table.bk") }}: {{ row.withdrawal_bk }}
          <br />
          {{ $t("clientsStats.table.sbp") }}: {{ row.withdrawal_sbp }}
        </div>

        <div v-if="row.refundsSum != 0" class="text-danger mt-2">
          {{ $t("clientsStats.table.bk") }}: {{ row.refundsSumBk }}
          <br />
          {{ $t("clientsStats.table.sbp") }}: {{ row.refundsSumSbp }}
        </div>
      </template>
      <template v-slot:fee_ap="{ row }">
        <div>{{ row.fee_ap }}</div>

        <div class="text-muted mt-2">
          {{ $t("clientsStats.table.bk") }}: {{ row.fee_ap_bk }}
          <br />
          {{ $t("clientsStats.table.sbp") }}: {{ row.fee_ap_sbp }}
        </div>
      </template>
      <template v-slot:fee_ps="{ row }">
        <div>{{ row.fee_ps }}</div>

        <div class="text-muted mt-2">
          {{ $t("clientsStats.table.bk") }}: {{ row.fee_ps_bk }}
          <br />
          {{ $t("clientsStats.table.sbp") }}: {{ row.fee_ps_sbp }}
        </div>
      </template>
    </v-server-table>

    <transition name="fade">
      <confirm-comp
        @confirm="confirm"
        :id="confirmId"
        :title="$t('main.confirm.title')"
        :text="$t('main.confirm.delete')"
        @cancel="cancel"
        v-if="confirmShow"
      />
    </transition>
  </div>
</template>

<script>
import Api from "@/api/v1/money-transfers-org";
import ConfirmComp from "@/components/ConfirmComp";
import downloadFile from "@/utils/downloadFile";

export default {
  name: "money-transfers-stats",
  components: {
    ConfirmComp,
  },
  data() {
    return {
      isInit: false,
      dateFrom: "",
      dateTo: "",
      statsData: null,
      clients: [],
      clientId: null,
      // selectedClient: null,
      tableOptions: {
        perPage: 150,
        columnsClasses: {
          status: "col-status text-end",
        },
        headings: {
          period: this.$t("clientsStats.table.period"),
          client: this.$t("clientsStats.table.client"),
          sum: this.$t("clientsStats.table.sum"),
          sum_bk: this.$t("clientsStats.table.sum_bk"),
          sum_sbp: this.$t("clientsStats.table.sum_sbp"),
          fee_ap: this.$t("clientsStats.table.fee_ap"),
          fee_ap_bk: this.$t("clientsStats.table.fee_ap_bk"),
          fee_ap_sbp: this.$t("clientsStats.table.fee_ap_sbp"),
          fee_ps: this.$t("clientsStats.table.fee_ps"),
          fee_ps_bk: this.$t("clientsStats.table.fee_ps_bk"),
          fee_ps_sbp: this.$t("clientsStats.table.fee_ps_sbp"),
          transfer: this.$t("clientsStats.table.transfer"),
          transfer_bk: this.$t("clientsStats.table.transfer_bk"),
          transfer_sbp: this.$t("clientsStats.table.transfer_sbp"),
          withdrawal: this.$t("clientsStats.table.withdrawal"),
          withdrawal_bk: this.$t("clientsStats.table.withdrawal_bk"),
          withdrawal_sbp: this.$t("clientsStats.table.withdrawal_sbp"),
          actions: "",
        },
      },

      modalForm: {
        show: false,
        id: null,
        title: "",
      },

      confirmShow: false,
      confirmId: null,
    };
  },
  computed: {
    tableColumns() {
      return ["period", "sum", "transfer", "withdrawal", "fee_ap", "fee_ps"];
    },
    selectedClient() {
      return 1;
    },
    ApiUrl() {
      return (
        Api.baseUrl +
        "/clients?expand=client,moneyTransfers&from=" +
        this.dateFrom +
        "&to=" +
        this.dateTo +
        "&client_id=" +
        this.clientId
      );
    },
  },
  watch: {
    dateFrom() {
      this.getStats();
    },
    dateTo() {
      this.getStats();
    },

    clientId() {
      this.isInit = true;
      this.getStats();
    },
  },
  methods: {
    async handleDownload(paymentType) {
      if (!this.isInit) {
        return false;
      }

      this.loading = true;
      const response = await Api.downloadClientsStats({
        from: this.dateFrom,
        to: this.dateTo,
        client_id: this.clientId,
        payment_type: paymentType,
      });
      let file = "clients_stats_.xls";
      downloadFile(response.data, file);
      this.loading = false;
    },

    async getStats() {
      if (!this.isInit) {
        return false;
      }
      const params = {
        from: this.dateFrom,
        to: this.dateTo,
        client_id: this.clientId,
      };
      const response = await Api.getClientsStats(params);
      this.statsData = response.data;
      await this.updateTable();
    },
    async getFilters() {
      const response = await Api.getFilters();
      this.clients = response.data.clients;
    },
    cancel() {
      this.confirmShow = false;
    },
    async confirm(id) {
      await Api.deleteModel(id);
      this.$refs.clientsTable.getData();
      this.confirmShow = false;
    },
    async updated() {
      await this.updateTable();
    },
    async updateTable() {
      // this.$set(this.tableOptions, "params", this.searchForm);
      await this.$refs.clientsTable.refresh();
    },
    setDefaultDate() {
      let currentDate = new Date();
      let currentMonth = (currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      let currentYear = currentDate.getFullYear();
      let currentDay = currentDate.getDate().toString().padStart(2, "0");

      this.dateFrom = currentYear + "-" + currentMonth + "-" + "01";
      this.dateTo = currentYear + "-" + currentMonth + "-" + currentDay;
    },
    handleRowClick({ row }) {
      row.id;
    },
  },
  async created() {
    this.getFilters();
    this.setDefaultDate();
  },
};
</script>
