<template>
  <div class="TableActionButtons">
    <slot name="prependButtons"></slot>
    <button
      v-if="viewRelation"
      size="sm"
      class="ml-1 btn btn-outline-secondary"
      @click.stop="getRelation"
    >
      <span class="micon micon__link"> </span>
    </button>
    <button
      v-if="viewVisible"
      size="sm"
      class="ml-1 btn btn-outline-secondary"
      @click.stop="viewClick"
    >
      <span class="micon micon__eye"> </span>
    </button>
    <button
      v-if="selectedVisible"
      size="sm"
      class="ml-1 btn btn-outline-secondary"
      @click.stop="selectedClick"
    >
      <font-awesome-icon fixed-width icon="check"> </font-awesome-icon>
    </button>
    <button
      v-if="updateVisible"
      size="sm"
      class="ml-1 btn btn-outline-secondary"
      @click.stop="updateClick"
    >
      <!-- <font-awesome-icon
                    fixed-width
                    icon="edit">
            </font-awesome-icon> -->
      <span class="micon micon__pencil"> </span>
    </button>
    <button
      v-if="deleteVisible"
      size="sm"
      class="ml-1 btn btn-outline-secondary"
      @click.stop="deleteClick"
    >
      <!-- <font-awesome-icon
                    fixed-width
                    icon="trash-alt">
            </font-awesome-icon> -->
      <span class="micon micon__delete"> </span>
    </button>
    <button
      v-if="printVisible"
      size="sm"
      class="ml-1 btn btn-outline-secondary"
      @click.stop="printClick"
    >
      <font-awesome-icon fixed-width icon="book-open"> </font-awesome-icon>
    </button>
    <button
      v-if="copyVisible"
      size="sm"
      class="ml-1 btn btn-outline-secondary"
      @click.stop="copyClick"
    >
      <span class="micon micon__copy"> </span>
    </button>
    <slot name="appendButtons"></slot>
  </div>
</template>

<script>
export default {
  props: {
    viewRelation: {
      type: Boolean,
      default: false,
    },
    viewVisible: {
      type: Boolean,
      default: false,
    },
    selectedVisible: {
      type: Boolean,
      default: false,
    },
    updateVisible: {
      type: Boolean,
      default: true,
    },
    deleteVisible: {
      type: Boolean,
      default: true,
    },
    printVisible: {
      type: Boolean,
      default: false,
    },
    copyVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    viewClick() {
      this.$emit("view");
    },
    selectedClick() {
      this.$emit("selected");
    },
    updateClick() {
      this.$emit("update");
    },
    deleteClick() {
      this.$emit("delete");
    },
    printClick() {
      this.$emit("print");
    },
    copyClick() {
      this.$emit("copy");
    },
    getRelation() {
      this.$emit("getRelation");
    },
  },
};
</script>
