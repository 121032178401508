export default {
    table: {
        username: 'Имя',
        email: 'Email',
        status: 'Статус',
    },
    form: {
        username: 'Фамилия и инициалы',
        email: 'Электронная почта',
        password: 'Пароль',
        placeholder:{
            email:''
        }
    }

}