<template>
  <div class="ClientsView">
    <button class="btn btn-primary add_client" @click="showForm(null)">
      {{ $t("main.create") }}
    </button>
    <v-server-table
      :url="ApiUrl"
      ref="clientTable"
      :columns="tableColumns"
      :options="options"
    >
      <template v-slot:title="{ row }">
        <div class="clientRow" @click="showModal(row)">
          <span>{{ row.alias }} {{ row.title }}</span>
          <span class="clientRow-contacts"
            >{{ row.phone }}
            <span v-if="row.phone !== '' && row.email !== ''"> / </span>
            {{ row.email }}</span
          >
          <span class="clientRow-date" v-if="row.time_start !== null">{{
            new Date(row.time_start * 1000).toLocaleString("ru-RU")
          }}</span>
        </div>
      </template>
      <template v-slot:status="{ row }">
        <div @click="showModal(row)">
          {{ $t("clients.table." + row.status) }}
        </div>
      </template>

      <template v-slot:actions="{ row }">
        <table-action-buttons
          :update-visible="true"
          :delete-visible="true"
          @delete="handleDelete(row.id)"
          @update="showForm(row.id)"
        >
        </table-action-buttons>
      </template>
    </v-server-table>

    <transition name="fade">
      <modal-comp
        v-if="clientModalForm.show"
        @close="clientModalForm.show = false"
      >
        <template v-slot:content>
          <clients-form
            @closeModal="clientModalForm.show = false"
            @updateTable="updateTable"
            @newClient="newClient"
            :id="clientModalForm.id"
          />
        </template>
      </modal-comp>
    </transition>

    <transition name="fade">
      <modal-comp
        @close="clientModalView.show = false"
        v-if="clientModalView.show"
      >
        <template v-slot:content>
          <clients-modal
            @closeModal="clientModalView.show = false"
            @updateTable="updateTable"
            :id="clientModalView.id"
            :data="clientModalView.data"
          />
        </template>
      </modal-comp>
    </transition>
  </div>

  <transition name="fade">
    <confirm-comp
      @confirm="confirm"
      :id="confirmId"
      :title="$t('main.confirm.title')"
      :text="$t('main.confirm.delete')"
      @cancel="cancel"
      v-if="confirmShow"
    />
  </transition>
</template>

<script>
import Api from "@/api/v1/clients";
import ModalComp from "@/components/ModalComp";
import ClientsForm from "@/components/clients/ClientsForm";
import ClientsModal from "@/components/clients/ClientsModal";
import TableActionButtons from "@/components/TableActionButtons";
import ConfirmComp from "@/components/ConfirmComp";

export default {
  data() {
    return {
      options: {
        perPage: 150,
        headings: {
          title: this.$t("clients.table.title"),
          status: this.$t("clients.table.status"),
          actions: "",
        },
      },
      ApiUrl: Api.baseUrl + "?expand=creator",
      targetForm: "clientsForm",

      clientModalForm: {
        show: false,
        id: null,
      },
      clientModalView: {
        show: false,
        data: null,
        title: "",
      },

      confirmShow: false,
      confirmId: null,
    };
  },
  computed: {
    tableColumns() {
      const actions = ["actions"];
      return ["title", "status", ...actions];
    },
  },
  components: {
    ModalComp,
    ClientsForm,
    TableActionButtons,
    ClientsModal,
    ConfirmComp,
  },
  methods: {
    cancel() {
      this.confirmShow = false;
    },
    async confirm(id) {
      await Api.deleteModel(id);
      this.$refs.clientTable.getData();
      this.confirmShow = false;
    },
    updateTable() {
      this.$refs.clientTable.refresh();
    },
    async handleDelete(id) {
      this.confirmId = id;
      this.confirmShow = true;
    },
    showForm(id) {
      this.clientModalForm.id = id;
      this.clientModalForm.show = true;
    },
    showModal(data) {
      this.clientModalView.data = data;
      this.clientModalView.title = data.title;
      this.clientModalView.show = true;
    },
    newClient(data) {
      this.clientModalForm.show = false;
      this.showModal(data);
    },
  },
  created() {
    let response = Api.getModels();
    this.users = response.data;
  },
};
</script>
