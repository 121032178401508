export default {
  STATUS_WAITING: 'В обработке',
  STATUS_COMPLETED: 'Выполнен',
  results: {
    revenue: 'Выручка за период',
    fee: 'Заработано',
    feeAverage: 'Средний чек'
  },
  table: {
    detail: 'Детали реестра',
    status: 'Статус',
    dateOperation: 'Дата выплаты',
    period: 'Период отчета',
    clientsCount: 'клиентов',
    refunds: 'Возвраты',
    amount: 'Итого',
  },
  form: {
    modalTitle: 'Реестр',
    confirmCompleteAll: 'Вы действительно хотите выполнить все?',
    status: 'Статус',
    dateOperation: 'Дата выплаты',
    period: 'Период отчета',
    clientsCount: 'Клиентов',
    revenue: 'Оборот',
    feeAp: 'AkratoPRIME',

    btnCreatePayment: 'Сформировать ПП',
    export: 'Экспорт в Excel',
    noAcceptance: 'Нет акцепта оферты',
    completeAll: 'Выполнить все',
    bk: 'Карты',
    sbp: 'СБП',
  },

  paymentsTable: {
    client: 'Клиент',
    sum: 'Выручка',
    fee: 'Комиссия',
    feePs: 'Банк',
    feeAp: 'AP',
    status: 'Статус',
    withdrawal: 'Выручка клиента',
    transfer: 'К зачислению на счет',

    btnStatusCompleted: 'Отправлено',
    confirmStatus: 'Действительно перевести в статус Выполнен операцию по клиенту'
  },
}
