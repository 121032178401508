<template>
  <footer class="footer">
    <div class="row footer-main">
      <div class="col-sm-12 col-md-6 footer-contacts">
        &copy; Web-сервис {{ projectName }}. 
        <a :href="orgLink" target="_blank">{{ orgName }} </a>,
        {{ year }}
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "simple-footer",
  props: {
    isSimple: {
      default: true,
    },
  },
  components: {},
  data() {
    return {
      year: "",
      orgName: process.env.VUE_APP_ORG_NAME,
      projectName: process.env.VUE_APP_PROJECT_NAME,
      orgLink: process.env.VUE_APP_ORG_LINK,
    };
  },
  methods: {},
  created() {
    let d = new Date();
    this.year = d.getFullYear();
  },
};
</script>