export default {
  table: {
    operation: 'Исходная операция',
    refund: 'Данные возврата',
    status: 'Статус',

    operationTime: 'Дата платежа',
    operationClient: 'Ученик',
    operationMethod: 'Способ',
    operationOptionBk: 'Карта',
    operationOptionSbp: 'СБП',
    operationSum: 'Сумма',
    operationPayerFIO: 'Плательщик',
    operationPayerEmail: 'Email',

    refundRequestDate: 'Дата поручения',
    refundSum: 'Сумма возврата',
    refundAccountSum: 'Затраты организации',
    refundDate: 'Дата возврата',
    refundReestr: 'Операции за',
    refundReestrBySum: 'на сумму',
  },

  form: {
    status: 'Статус',
    pleaseSelectReestr: 'Выбрать реестр',
    dateRefund: 'Дата возврата'
  },
}
