<template>
  <div class="UsersView">
    <button
      id="openModal"
      class="btn btn-primary add_user"
      @click="showForm(null)"
    >
      {{ $t("main.create") }}
    </button>
    <v-server-table
      :url="ApiUrl"
      ref="usersTable"
      :columns="tableColumns"
      :options="options"
    >
      <template v-slot:username="{ row }">
        <div @click="showForm(row.id)">
          {{ row.username }}
        </div>
      </template>
      <template v-slot:email="{ row }">
        <div @click="showForm(row.id)">
          {{ row.email }}
        </div>
      </template>
      <template v-slot:actions="{ row }">
        <table-action-buttons
          :update-visible="false"
          :delete-visible="true"
          @delete="handleDelete(row.id)"
        >
        </table-action-buttons>
      </template>
    </v-server-table>

    <transition name="fade">
      <modal-comp
        @close="usersModalForm.show = false"
        v-if="usersModalForm.show"
        :title="usersModalForm.title"
      >
        <template v-slot:content>
          <UsersForm
            @updateTable="updateTable"
            :id="usersModalForm.id"
            @closeModal="usersModalForm.show = false"
          />
        </template>
      </modal-comp>
    </transition>

    <transition name="fade">
      <confirm-comp
        @confirm="confirm"
        :id="confirmId"
        :title="$t('main.confirm.title')"
        :text="$t('main.confirm.delete')"
        @cancel="cancel"
        v-if="confirmShow"
      />
    </transition>
  </div>
</template>

<script>
import Api from "@/api/v1/users";
import ModalComp from "@/components/ModalComp";
import UsersForm from "@/components/users/UsersForm";
import TableActionButtons from "@/components/TableActionButtons";
import ConfirmComp from "@/components/ConfirmComp";

export default {
  data() {
    return {
      options: {
        headings: {
          username: this.$t("users.table.username"),
          email: this.$t("users.table.email"),
          actions: "",
        },
      },
      ApiUrl: Api.baseUrl,
      target: "usersModal",

      usersModalForm: {
        show: false,
        id: null,
        title: "",
      },

      confirmShow: false,
      confirmId: null,
    };
  },
  components: {
    ConfirmComp,
    ModalComp,
    UsersForm,
    TableActionButtons,
  },
  computed: {
    tableColumns() {
      const actions = ["actions"];
      return ["username", "email", ...actions];
    },
  },
  methods: {
    cancel() {
      this.confirmShow = false;
    },
    async confirm(id) {
      await Api.deleteModel(id);
      this.$refs.usersTable.getData();
      this.confirmShow = false;
    },
    updateTable() {
      this.$refs.usersTable.refresh();
    },
    closeModal() {
      this.usersModal.hide();
    },
    showForm(id) {
      this.usersModalForm.id = id;
      this.usersModalForm.show = true;
    },
    async handleDelete(id) {
      this.confirmId = id;
      this.confirmShow = true;
    },
  },
  created() {
    const response = Api.getModels();
    this.users = response.data;
  },
};
</script>
