<template>
  <div class="logs">
    <v-server-table
      :url="ApiUrl"
      ref="logsTable"
      :columns="tableColumns"
      :options="tableOptions"
      @row-click="handleRowClick"
    >
      <template v-slot:client="{ row }">
        <div>{{ row.client.title }}</div>
      </template>

      <template v-slot:type="{ row }">
        <div>{{ row.typeTitle }}</div>
      </template>

      <template v-slot:detail="{ row }">
        <div v-html="getPreparedText(row.detail)"></div>
      </template>

      <template v-slot:created_at="{ row }">
        <div>{{ row.createdAtFormatted }}</div>
      </template>
    </v-server-table>

    <!--<transition name="fade">-->
    <!--  <modal-comp-->
    <!--    @close="modalForm.show = false"-->
    <!--    v-if="modalForm.show"-->
    <!--    :title="modalForm.title"-->
    <!--    size="lg"-->
    <!--  >-->
    <!--    <template v-slot:content>-->
    <!--      <logs-form-->
    <!--        :id="modalForm.id"-->
    <!--        @updated="updated"-->
    <!--      />-->
    <!--    </template>-->
    <!--  </modal-comp>-->
    <!--</transition>-->
  </div>
</template>

<script>
import Api from "@/api/v1/logs";
// import TransfersForm from "@/components/logs/TransfersForm";
// import ModalComp from "@/components/ModalComp";

export default {
  name: 'logs-view',
  components: {
    // ModalComp,
    // TransfersForm,
  },
  data() {
    return {
      ApiUrl: Api.baseUrl + '?expand=client',

      tableOptions: {
        perPage: 50,
        columnsClasses: {},
        headings: {
          client: this.$t("logs.table.client"),
          type: this.$t("logs.table.type"),
          detail: this.$t("logs.table.detail"),
          created_at: this.$t("logs.table.createdAt"),
          actions: "",
        },
      },

      modalForm: {
        show: false,
        id: null,
        title: "",
      },
    };
  },
  computed: {
    tableColumns() {
      return ['client', 'type', 'detail', 'created_at'];
    },
  },
  methods: {
    getPreparedText(text) {
      return text.replace(/\n/g, "<br>");
    },
    // eslint-disable-next-line no-unused-vars
    handleRowClick({row}) {
      // this.modalForm.id = row.id;
      // this.modalForm.show = true;
      // this.modalForm.title = this.$t("moneyTransfers.form.modalTitle") + (row.operationDateFormatted ? (' ' + row.operationDateFormatted) : '');
    },
  },
  created() {
  },
};
</script>
