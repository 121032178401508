<template>
  <div class="transfers-form">
    <div class="form-group">
      <div>
        <div>
          <span class="fw-bolder me-2"
            >{{ $t("moneyTransfers.form.dateOperation") }}:</span
          >
          <span>{{ form.operationDateFormatted }}</span>
        </div>
      </div>
      <div>
        <span class="fw-bolder me-2"
          >{{ $t("moneyTransfers.form.period") }}:</span
        >
        <span v-if="form.reporting_days === 1" class="">{{
          form.periodFromFormatted
        }}</span>
        <span v-else
          >{{ form.periodFromFormatted }} - {{ form.periodToFormatted }}</span
        >
      </div>
      <div>
        <div>
          {{ $t("moneyTransfers.form.clientsCount") }}:
          {{ form.detail.clientsCount }}
        </div>
        <div>
          {{ $t("moneyTransfers.form.revenue") }}: {{ form.detail.sum }}
          <span class="text-muted small">
            {{ form.detail.sum_sbp }} {{ $t("moneyTransfers.form.sbp") }} +
            {{ form.detail.sum_bk }} {{ $t("moneyTransfers.form.bk") }}
          </span>
        </div>
        <div>
          {{ $t("moneyTransfers.form.feeAp") }}: {{ form.detail.feeAp }}
          <span class="text-muted small">
            {{ form.detail.feeAp_sbp }} {{ $t("moneyTransfers.form.sbp") }} +
            {{ form.detail.feeAp_bk }} {{ $t("moneyTransfers.form.bk") }}
          </span>
        </div>
      </div>
    </div>

    <div class="form-group">
      <button
        style="margin-right: 1em"
        class="btn btn-outline-success"
        @click="handleDownload"
      >
        {{ $t("moneyTransfers.form.export") }}
      </button>
      <button class="btn btn-outline-primary" @click="handleCompleteAll">
        {{ $t("moneyTransfers.form.completeAll") }}
      </button>
    </div>

    <v-server-table
      :url="ApiUrl"
      ref="transfersTable"
      :columns="tableColumns"
      :options="tableOptions"
    >
      <template v-slot:client="{ row }">
        <div>{{ row.client.title }} ({{ row.client.alias }})</div>
        <div class="small text-muted">{{ row.recipient }}</div>
        <div v-if="row.offer_accepted" class="small text-muted">
          {{ row.offer_accepted }}
        </div>
        <div v-else class="small text-danger">
          {{ $t("moneyTransfers.form.noAcceptance") }}
        </div>
      </template>

      <template v-slot:sum="{ row }">
        <div>{{ row.sum }}</div>
        <div>&nbsp;</div>
        <div class="small text-muted">
          {{ $t("moneyTransfers.form.bk") }}: {{ row.sum_bk }}
        </div>
        <div class="small text-muted">
          {{ $t("moneyTransfers.form.sbp") }}: {{ row.sum_sbp }}
        </div>
      </template>

      <template v-slot:transfer="{ row }">
        <div>{{ row.transfer }}</div>
        <div>&nbsp;</div>
        <div class="small text-muted">
          {{ $t("moneyTransfers.form.bk") }}: {{ row.transfer_bk }}
        </div>
        <div class="small text-muted">
          {{ $t("moneyTransfers.form.sbp") }}: {{ row.transfer_sbp }}
        </div>
      </template>

      <template v-slot:withdrawal="{ row }">
        <div>{{ row.withdrawal }}</div>
        <div
          :class="{ 'text-danger': row.refundsSum != 0 }"
          v-if="row.refundsSum != 0"
        >
          <span class="font-weight-bolder mr-2"
            >{{ $t("moneyTransfers.table.refunds") }}:
          </span>
          <span class="">{{ row.refundsSum }}</span>
        </div>

        <div class="" v-if="row.refundsSum != 0">
          <span class="font-weight-bolder mr-2"
            >{{ $t("moneyTransfers.table.amount") }}:
          </span>
          <span class="">{{ row.amount }}</span>
        </div>

        <div>&nbsp;</div>
        <div class="small text-muted">
          {{ $t("moneyTransfers.form.bk") }}: {{ row.withdrawal_bk }}
        </div>
        <div class="small text-muted">
          {{ $t("moneyTransfers.form.sbp") }}: {{ row.withdrawal_sbp }}
        </div>

        <div>&nbsp;</div>
        <div v-if="row.refundsSum != 0" class="small text-danger">
          <span>{{ $t("moneyTransfers.form.bk") }}: {{ row.refundsSumBk }}</span>
        </div>
        <div v-if="row.refundsSum != 0" class="small text-danger">
          <span>{{ $t("moneyTransfers.form.sbp") }}: {{ row.refundsSumSbp }}</span>
        </div>
      </template>

      <template v-slot:fee="{ row }">
        <div>{{ row.fee }}</div>
        <div>&nbsp;</div>
        <div class="small text-muted">
          <span>{{ $t("moneyTransfers.paymentsTable.feePs") }}: </span>
          <span>{{ row.fee_ps }}</span>
        </div>
        <div class="small text-muted">
          <span>{{ $t("moneyTransfers.form.bk") }}: {{ row.fee_ps_bk }}</span>
        </div>
        <div class="small text-muted">
          <span>{{ $t("moneyTransfers.form.sbp") }}: {{ row.fee_ps_sbp }}</span>
        </div>
        <div>&nbsp;</div>
        <div class="small text-muted">
          <span>{{ $t("moneyTransfers.paymentsTable.feeAp") }}: </span>
          <span>{{ row.fee_ap }}</span>
        </div>
        <div class="small text-muted">
          <span>{{ $t("moneyTransfers.form.bk") }}: {{ row.fee_ap_bk }}</span>
        </div>
        <div class="small text-muted">
          <span>{{ $t("moneyTransfers.form.sbp") }}: {{ row.fee_ap_sbp }}</span>
        </div>
      </template>

      <template v-slot:status="{ row }">
        <div class="text-end">
          <button
            v-if="row.status === STATUS_WAITING"
            class="btn btn-sm btn-outline-primary"
            @click="changePaymentStatus(row)"
          >
            {{ $t("moneyTransfers.paymentsTable.btnStatusCompleted") }}
          </button>
          <button
            v-if="row.status === STATUS_COMPLETED"
            :disabled="true"
            class="btn btn-sm btn-outline-success"
            @click="changePaymentStatus(row)"
          >
            {{ $t("moneyTransfers.STATUS_COMPLETED") }}
          </button>
        </div>
      </template>

      <template v-slot:is_admin_invoices="{ row }">
        <div v-if="row.is_admin_invoices" class="text-center point text-danger">
          !
        </div>
      </template>
    </v-server-table>

    <transition name="fade">
      <confirm-comp
        @confirm="confirm"
        :id="confirmId"
        :title="$t('main.confirm.title')"
        :text="confirmText"
        @cancel="cancel"
        v-if="confirmShow"
      />
    </transition>
  </div>
</template>
<script>
import Form from "@/utils/Form";
import Api from "@/api/v1/money-transfers";
import ApiOrg from "@/api/v1/money-transfers-org";
import { STATUS_COMPLETED, STATUS_WAITING } from "@/utils/transfers";
import ConfirmComp from "@/components/ConfirmComp.vue";
import downloadFile from "@/utils/downloadFile";

export default {
  name: "transfers-form",
  props: {
    id: Number,
  },
  components: { ConfirmComp },
  emits: ["closeModal", "updateTable", "updated"],
  data() {
    return {
      STATUS_WAITING: STATUS_WAITING,
      STATUS_COMPLETED: STATUS_COMPLETED,

      ApiUrl: ApiOrg.baseUrl,

      form: new Form({
        id: 4,
        reporting_days: 5,
        periodFromFormatted: "",
        periodToFormatted: "",
        operationDateFormatted: null,
        status: 0,
        statusTitle: "",
        detail: {
          clientsCount: 0,
          sum: 0,
          fee: 0,
          feeAp: 0,
        },
      }),

      tableOptions: {
        perPage: 150,
        columnsClasses: {
          client: "client",
          sum: "sum",
          transfer: "transfer",
          withdrawal: "withdrawal",
          fee: "fee",
        },
        headings: {
          client: this.$t("moneyTransfers.paymentsTable.client"),
          sum: this.$t("moneyTransfers.paymentsTable.sum"),
          transfer: this.$t("moneyTransfers.paymentsTable.transfer"),
          fee: this.$t("moneyTransfers.paymentsTable.fee"),
          withdrawal: this.$t("moneyTransfers.paymentsTable.withdrawal"),
          status: this.$t("moneyTransfers.paymentsTable.status"),
          is_admin_invoices: "",
        },
        params: {
          transfers_id: this.id,
          expand: "client",
        },
      },

      confirmId: null,
      confirmShow: false,
      confirmText: "",
    };
  },
  computed: {
    tableColumns() {
      return [
        "client",
        "sum",
        "transfer",
        "withdrawal",
        "fee",
        "status",
        "is_admin_invoices",
      ];
    },
  },
  watch: {},
  methods: {
    async load(id) {
      const response = await Api.getModel(id, {
        expand: "detail",
      });
      this.form.load(response.data);
      this.form.id = id;
    },
    async createPayment() {},
    changePaymentStatus(row) {
      this.confirmId = row.id;
      this.confirmShow = true;
      this.confirmText =
        this.$t("moneyTransfers.paymentsTable.confirmStatus") +
        " " +
        row.client.title +
        "?";
    },
    async confirm(id) {
      await ApiOrg.setStatus(id, STATUS_COMPLETED);
      this.$emit("updated");
      this.cancel();
      this.updateTransfersTable();
    },
    cancel() {
      this.confirmId = null;
      this.confirmShow = false;
      this.confirmText = "";
    },
    updateTransfersTable() {
      this.$refs.transfersTable.refresh();
    },
    async handleCompleteAll() {
      let isComplete = confirm(
        this.$t("moneyTransfers.form.confirmCompleteAll")
      );
      if (isComplete) {
        this.loading = true;
        const response = await ApiOrg.setCompleteAll({
          money_transfers_id: this.id,
        });
        if (response.data === true) {
          this.updateTransfersTable();
        }
        this.loading = false;
      }
    },
    async handleDownload() {
      this.loading = true;
      const response = await ApiOrg.download({
        transfers_id: this.id,
      });
      let file = "transfers_" + this.form.periodFromFormatted + ".xls";
      downloadFile(response.data, file);
      this.loading = false;
    },
  },
  async created() {
    await this.load(this.id);
  },
};
</script>
