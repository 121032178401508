import { createRouter, createWebHistory } from 'vue-router'
import i18n from '../i18n'

import UsersView from '../views/UsersView'
import ClientsView from '../views/ClientsView'
import MoneyTransfersView from '../views/MoneyTransfersView'
import MoneyTransfersStats from '../views/MoneyTransfersStats'
import MoneyTransfersRefunds from '../views/MoneyTransfersRefunds'
import SignIn from '../views/SignIn'
import store from '@/store'
import SimpleFooter from '@/layouts/SimpleFooter'
import SimpleHeader from '@/layouts/SimpleHeader'
import SimpleSidebar from '@/layouts/SimpleSidebar'
import ErrorView from '../views/ErrorView'
import Logs from "../views/LogsView";


const isNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const routes = [
  {
    path: '/',
    name: 'UsersView',
    meta: {
      title: i18n.global.t('main.meta.employees'),
      layout: 'simple',
    },
    components: {
      default: UsersView,
      footer: SimpleFooter,
      sidebar: SimpleSidebar,
      header: SimpleHeader
    }
  },
  {
    path: '/login',
    name: 'SignIn',
    meta: {
      title: i18n.global.t('main.meta.login'),
      layout: 'empty',
    },
    components: {
      default: SignIn,
    },

    beforeEnter: isNotAuthenticated,
  },
  {
    path: '/clients',
    name: 'ClientsView',
    meta: {
      title: i18n.global.t('main.meta.clients'),
      layout: 'simple',
    },
    components: {
      default: ClientsView,
      footer: SimpleFooter,
      sidebar: SimpleSidebar,
      header: SimpleHeader
    },
  },
  {
    path: '/money-transfers',
    name: 'MoneyTransfersView',
    meta: {
      title: i18n.global.t('main.meta.moneyTransfers'),
      layout: 'simple',
    },
    components: {
      default: MoneyTransfersView,
      footer: SimpleFooter,
      sidebar: SimpleSidebar,
      header: SimpleHeader
    },
  },
  {
    path: '/clients-stats',
    name: 'MoneyTransfersStats',
    meta: {
      title: i18n.global.t('main.meta.moneyTransfersStats'),
      layout: 'simple',
    },
    components: {
      default: MoneyTransfersStats,
      footer: SimpleFooter,
      sidebar: SimpleSidebar,
      header: SimpleHeader
    },
  },
  {
    path: '/clients-refunds',
    name: 'MoneyTransfersRefunds',
    meta: {
      title: i18n.global.t('main.meta.moneyTransfersRefunds'),
      layout: 'simple',
    },
    components: {
      default: MoneyTransfersRefunds,
      footer: SimpleFooter,
      sidebar: SimpleSidebar,
      header: SimpleHeader
    },
  },
  {
    path: '/logs',
    name: 'LogsView',
    meta: {
      title: i18n.global.t('main.meta.logs'),
      layout: 'simple',
    },
    components: {
      default: Logs,
      footer: SimpleFooter,
      sidebar: SimpleSidebar,
      header: SimpleHeader
    },
  },
  {
    path: '/error',
    name: 'error',
    meta: {
      layout: 'empty',
      title: i18n.global.t('main.meta.error'),
    },
    component: ErrorView
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/error'
  },

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  //Set layout
  let layout = (to.meta.layout || 'simple');
  if (layout !== store.getters.layout) {
    store.commit('SET_LAYOUT', layout);
  }

  //Set page title
  let pageTitle = to.meta.title ? to.meta.title : '';
  document.title = pageTitle;

  next();
});


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
      return;
    }
    next();
    return;
  }
  next();
});

export default router
