<template>
  <section class="wrapper-content">
    <router-view></router-view>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
.wrapper-content {
  background-color: #e9ecef;
  height: 100%;
  min-height: 100vh;
}
</style>