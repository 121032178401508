<template>
  <div class="error">
    <div class="card error-box">
      <h1 class="card-title">{{ $t("main.error.occurred") }}</h1>

      <p class="card-text error-desc">{{ $t("main.error.contactUs") }}</p>

      <div class="card-body">
        <router-link to="/" class="btn btn-primary">
          {{ $t("main.toHome") }}
        </router-link>
        <router-link
          v-if="canGoBack"
          class="btn btn-warning ms-1"
          :to="{ path: `/previous-page` }"
          @click="goBack"
        >
          {{ $t("main.back") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "error-view",
  computed: {
    canGoBack() {
      return window.history.length > 1;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  max-width: 700px;
  margin: 0 auto;
  padding: 100px 15px 15px 15px;
}

.error-box {
  text-align: center;
}

.error-logo {
  max-width: 370px;
}
</style>