<template>
  <div class="confirm-comp" @click="handleClickOutside">
    <div class="content" ref="modal">
      <h2>{{ title }}</h2>
      <span>{{ text }}</span>

      <div class="content-buttons">
        <button class="content-buttons-confirm" @click="$emit('confirm', id)">
          {{ $t("main.confirm.yes") }}
        </button>
        <button class="content-buttons-cancel" @click="$emit('cancel')">
          {{ $t("main.confirm.no") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirm-comp",
  emits: ["confirm", "cancel"],
  props: {
    id: Number,
    title: String,
    text: String,
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleClickOutside(event) {
      const modal = this.$refs.modal;
      if (modal != null) {
        if (!modal.contains(event.target)) {
          this.$emit("cancel");
        }
      }
    },
  },
  created() {},
};
</script>
