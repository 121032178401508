<template>
  <router-link class="brand" to="/">
    {{ shop }}
  </router-link>
</template>

<script>
export default {
  name: "logo-comp",
  components: {},
  data() {
    return {
      shop: process.env.VUE_APP_SHOP
    };
  },
  computed: {},
  methods: {},
  created() {
  },
};
</script>
