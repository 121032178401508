<template>
  <header class="header">
    <div class="header-navbar">
      <em>{{ profile.username }}</em>
      <span @click="logout">{{ $t("main.menu.logout") }}</span>
    </div>
  </header>
</template>

<script>
import { AUTH_LOGOUT } from "@/store/modules/auth/actions";
import { mapGetters } from "vuex";

export default {
  name: "simple-header",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ profile: "getProfile" }),
  },
  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT);
      this.$router.push("/login");
    },
  },
  created() {},
};
</script>
