import api from '../'

export default {
    auth(user) {
        return api.post('/auth', user);
    },
    profile() {
        return api.get('/user/me', {
        });
    },
};
