import { USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_LOGOUT, USER_CHANGE_VISIT_STATUS } from './actions'
import api from '@/api/v1/user'

const state = {
    status: '',
    profile: {
        id: null,
        name: '',
        roles: [],
        permissions: [],

    },
    isAdmin: false,
    visitStatus: null,
};

const getters = {
    getProfile: state => state.profile,
    getUserId: state => state.profile ? state.profile.id : null,
    isProfileLoaded: state => !!state.profile.name,
    withoutRight: state => {
        return state.profile.rights && state.profile.rights.length > 0
    },
    getVisitStatus: state => state.visitStatus,
    isAdmin: state => state.isAdmin,
    // hasPermission: state => (permission) => {
    //     return true;
    //     // return state.profile.permissions.includes(permission);
    // },
};

const actions = {
    [USER_REQUEST]: async ({ commit }) => {
        commit(USER_REQUEST);
        try {
            const response = await api.profile();
            commit(USER_SUCCESS, response.data);
        } catch (error) {
            commit(USER_ERROR);
            throw error;
        }
    },
    [USER_CHANGE_VISIT_STATUS]: async ({ commit }, fingerprint) => {
        const response = await api.changeVisitStatus(fingerprint);
        if (response.data) {
            commit(USER_CHANGE_VISIT_STATUS)
        }
    },
};

const mutations = {
    [USER_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [USER_SUCCESS]: (state, profile) => {
        state.status = 'success';
        state.profile = profile;
        state.visitStatus = profile.visitStatus

        if (profile.rights !== undefined) {
            state.isAdmin = profile.rights[0].item_name === 'admin';
        }
    },
    [USER_ERROR]: (state) => {
        state.status = 'error'
    },
    [USER_LOGOUT]: (state) => {
        state.profile = {}
    },
    [USER_CHANGE_VISIT_STATUS]: (state) => {
        state.visitStatus = !state.visitStatus
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
